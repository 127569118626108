<template>
  <div class="loading">
    <van-loading />
  </div>
</template>
<script>
import qs from 'qs'
import Cookies from 'js-cookie'
export default {
  mounted(){
    this.getUrlParams()
  },
  methods: {
    getUrlParams(){
      const urlParams = qs.parse(window.location.href.split('?')[1])

      if(urlParams.wx_token){
        Cookies.set('wx_token', urlParams.wx_token)
      }
      if(urlParams.token){
        Cookies.set('x-auth-token', urlParams.token)
      }
      setTimeout(() => {
        // console.log(urlParams.origin_url)
        // this.$router.push({path: urlParams.origin_url.split('#')[1]})
        location.href = urlParams.origin_url
        // this.$router.push({path: urlParams.origin_url})
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.loading{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
</style>